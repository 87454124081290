import React, {useEffect} from 'react';
import ScrollSpy from 'react-scrollspy';
import { Link } from 'gatsby';
import { FiMenu, FiX } from "react-icons/fi";

const Header = () => {

    useEffect(() => {
        window.addEventListener('scroll', function() {
            let value = window.scrollY;
            if (value > 50) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        let elements = document.querySelectorAll('.has-droupdown > a');

        for(let i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    console.log('.submenu');
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }
    });

    const menuTrigger = () => {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    };

    const closeMenuTrigger = () => {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    };

    return (
        <header className="header-area formobile-menu header--fixed default-color">
            <div className="header-wrapper" id="header-wrapper">
                <div className="header-left">
                    <div className="logo">
                        <Link to="/">
                        </Link>
                    </div>
                </div>
                <div className="header-right">
                    <nav className="mainmenunav d-lg-block">
                        <ScrollSpy className="mainmenu"
                                   items={[]}
                                   currentClassName="is-current" offset={-200}>

                            <li><Link to="/">Prva stran</Link></li>

                            <li className="has-droupdown">
                                <Link to="/obisci-rudnik/glavni-rov" className="">Obišči rudnik</Link>
                                <ul className="submenu">
                                    <li><Link to="/obisci-rudnik/glavni-rov">Glavni rov<span className="badge badge-primary">NOVO</span></Link></li>
                                    <li><Link to="/obisci-rudnik/izvozni-rov">Izvozni rov</Link></li>
                                    <li><Link to="/obisci-rudnik/animirana-vodenja">Animirana vodenja</Link></li>
                                    <li><Link to="/obisci-rudnik/kulinarika">Kulinarika</Link></li>
                                    <li><Link to="/obisci-rudnik/tradicionalni-dogodki">Dogodki</Link></li>
                                    <li><Link to="/obisci-rudnik/trgovina-in-spominki">Trgovina in spominki</Link></li>
                                </ul>
                            </li>

                            <li><Link to="/razisci-rudnik-sitarjevec">Razišči rudnik</Link></li>
                            <li><Link to="/cenik">Cenik</Link></li>
                            <li><Link to="/kontakt-in-informacije">Kontakt in informacije</Link></li>
                        </ScrollSpy>
                    </nav>
                    {/* Start Humberger Menu  */}
                    <div className="humberger-menu d-block d-lg-none pl--20">
                        <span onClick={menuTrigger} className="menutrigger text-white"><FiMenu/></span>
                    </div>
                    {/* End Humberger Menu  */}
                    <div className="close-menu d-block d-lg-none">
                        <span onClick={closeMenuTrigger} className="closeTrigger"><FiX/></span>
                    </div>
                </div>
            </div>
        </header>
    )
};

export default Header;
